import { graphql, navigate } from "gatsby"
// import { Link } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

// import ClientLogo from "../components/ClientLogo"
// import ItemRows from "../components/ItemRows"
import { PageLayout } from "../components/PageLayout"
// import { SectionTitle } from "../components/SectionTitle"
import {
  createStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"

export const query = graphql`
  query ClientsPageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_clients: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "clients" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

const ClientsPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  // const clients = transformToArray(props.data, "clients")
  const content = createStaticContent(props.data, "clients")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      {/* <SectionTitle
        title={formatStaticContent(content, "clients-title")}
        desc={formatStaticContent(content, "clients-desc")}
        bg_icon={"lineart4"}
      />
      <PageContentsWrapper>
        <ItemRows compact child_limit={0}>
          {_.map(clients, (client, i) => (
            <Link to={`/clients/${client.client}`} key={i}>
              <ClientLogo>
                <img
                  src={_.get(client, "logo.0.url")}
                  alt={`${client.name} Logo`}
                />
              </ClientLogo>
            </Link>
          ))}
        </ItemRows>
      </PageContentsWrapper> */}
    </PageLayout>
  )
}

export default ClientsPage
